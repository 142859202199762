/*
@File: Pungent Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below >>>>> 

*******************************************
*******************************************

** - Default CSS
** - Header CSS
** - Main Banner CSS
** - Featured Services CSS
** - About CSS
** - Case Studies CSS
** - Quotes CSS
** - Services CSS
** - Services Details CSS
** - Partner CSS
** - Feedback CSS
** - Portfolio CSS
** - Team CSS
** - Pricing CSS
** - Our Work Process CSS
** - CTA CSS
** - Fun Facts CSS
** - Subscribe CSS
** - Blog CSS
** - Blog Details CSS
** - Page Title CSS
** - Contact CSS
** - 404 Error CSS
** - Pagination CSS
** - Sidebar CSS
** - Case Studies Details CSS
** - Portfolio Details CSS
** - FAQ CSS
** - Footer CSS
** - Go Top CSS
*/
/* Default CSS */
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #646464;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Saira", sans-serif;
  color: #18181b;
  font-weight: bold;
}

h1, h2, h3 {
  font-weight: bold;
}

h4, h5, h6 {
  font-weight: 600;
}

p {
  line-height: 1.8;
  font-size: 15px;
  color: #646464;
  margin-bottom: 15px;
}
p:last-child {
  margin-bottom: 0;
}

a {
  transition: all 0.5s ease;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

button {
  outline: 0 !important;
}

.border {
  border-color: #eee !important;
}

.radius-10 {
  border-radius: 10px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-0 {
  padding-left: 0 !important;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-fcfbfb {
  background-color: #f6f3f3;
}

.bg-f9f6f6 {
  background-color: #f9f6f6;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mw-1920 {
  max-width: 1920px;
  margin: auto;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

a {
  display: inline-block;
  transition: all 0.5s ease;
  text-decoration: none;
  transition: all 0.5s ease;
}
a:hover, a:focus {
  outline: 0 !important;
  color: #ff2c9c;
  text-decoration: none;
}

.rounded-10 {
  border-radius: 10px;
}

/* btn */
.btn {
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  transition: all 0.5s ease;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-weight: 600;
  font-size: 15px;
}
.btn.disabled, .btn:disabled {
  opacity: 1;
}

.btn-primary {
  color: #ffffff;
  background-color: #ffffff;
  border: 1px solid #ff2c9c;
}
.btn-primary::before {
  border-radius: 5px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  transition: all 0.5s ease;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  color: #ff2c9c;
  box-shadow: unset;
  background-color: transparent;
  border-color: #ff2c9c;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #ffffff;
  border: 1px solid #ff2c9c;
}
.btn-primary:hover, .btn-primary:focus {
  color: #ff2c9c;
  box-shadow: unset;
  background-color: transparent;
  border-color: #ff2c9c;
}
.btn-primary:hover::before, .btn-primary:focus::before {
  width: 0;
}

.btn-secondary {
  color: #ff2c9c;
  background-color: transparent;
  border: 1px solid #ff2c9c;
}
.btn-secondary::before {
  border-radius: 5px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  transition: all 0.5s ease;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  color: #ffffff;
  box-shadow: unset;
  background-color: #ffffff;
  border-color: transparent;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ff2c9c;
  background-color: transparent;
  border: 1px solid #ff2c9c;
}
.btn-secondary:hover, .btn-secondary:focus {
  color: #ffffff;
  box-shadow: unset;
  background-color: #ffffff;
  border-color: transparent;
}
.btn-secondary:hover::before, .btn-secondary:focus::before {
  width: 100%;
}

.default-btn-two {
  border: 2px solid #f9004d;
  background: #f9004d;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 13px 30px;
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.5s ease;
}
.default-btn-two:hover, .default-btn-two:focus {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}

/* section-title */
.section-title {
  text-align: center;
  margin: -5px auto 60px;
  max-width: 720px;
}
.section-title h2 {
  margin-bottom: 15px;
  font-size: 50px;
}
.section-title p {
  margin-bottom: 0;
  margin: 0 auto;
  max-width: 650px;
}
.section-title .sub-title {
  display: block;
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
}
.section-title.white-color .sub-title, .section-title.white-color h2 {
  color: #ffffff;
}
.section-title .sub-title {
  display: block;
  margin-bottom: 10px;
  color: #ff2c9c;
  font-weight: 600;
  font-size: 14px;
}

/* form control */
.form-group {
  margin-bottom: 25px;
}

label {
  color: #18181b;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.form-control {
  height: 55px;
  padding: 15px 20px;
  font-size: 14px;
  color: #18181b;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background: #eeeeee;
  transition: all 0.5s ease;
}
.form-control:focus {
  outline: 0;
  background: #ffffff;
  box-shadow: unset;
  border-color: #ff2c9c;
}

/*================================================
Navbar CSS
=================================================*/
.navbar-area {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 15px;
  width: 100%;
  z-index: 999;
  height: auto;
}
.navbar-area nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.navbar-area nav .navbar-brand {
  font-weight: bold;
  font-size: 28px;
}
.navbar-area nav .navbar-nav {
  margin-left: auto;
}
.navbar-area nav .navbar-nav .nav-item {
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* Mega dropdown menu */
}
.navbar-area nav .navbar-nav .nav-item a {
  color: #18181b;
  text-transform: capitalize;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area nav .navbar-nav .nav-item a::before {
  bottom: -5px;
  width: 0;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  height: 3px;
  transition: all 0.5s ease;
  left: 1px;
  content: "";
  position: absolute;
}
.navbar-area nav .navbar-nav .nav-item a:hover, .navbar-area nav .navbar-nav .nav-item a:focus, .navbar-area nav .navbar-nav .nav-item a.active {
  color: #18181b;
}
.navbar-area nav .navbar-nav .nav-item a:hover::before, .navbar-area nav .navbar-nav .nav-item a:focus::before, .navbar-area nav .navbar-nav .nav-item a.active::before {
  width: 60%;
}
.navbar-area nav .navbar-nav .nav-item a i {
  font-size: 15px;
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 2px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  background: #ffffff;
  position: absolute;
  top: 80px;
  left: 0;
  border: none;
  display: block;
  width: 250px;
  z-index: 99;
  opacity: 0;
  border-radius: 5px;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 7px 15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a {
  display: inline-block;
  font-size: 14px;
  color: #18181b;
  font-weight: 500;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a::before {
  display: none;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff2c9c;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  position: absolute;
  left: 220px;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #18181b;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #ff2c9c;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -20px !important;
}
.navbar-area nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.navbar-area nav .navbar-nav .nav-item .mega-dropdown-menu {
  width: 500px;
  padding: 0 10px 5px;
}
.navbar-area nav .navbar-nav .nav-item .mega-dropdown-menu .nav-item {
  width: 50%;
  float: left;
}
.navbar-area nav .navbar-nav .nav-item .mega-dropdown-menu .nav-item a {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5 !important;
  display: block;
}
.navbar-area nav .navbar-nav .nav-item .mega-dropdown-menu .nav-item a:last-child {
  border-bottom: none !important;
}
.navbar-area nav .others-options {
  padding: 0;
  margin: 0 0 0 10px;
  list-style-type: none;
}
.navbar-area nav .others-options .btn {
  border-radius: 50px;
  padding: 11px 20px;
  font-size: 14px;
}
.navbar-area nav .others-options .btn::before {
  border-radius: 50px;
}
.navbar-area.navbar-style-two {
  padding-left: 55px;
  padding-right: 55px;
}
.navbar-area.navbar-style-two nav .navbar-nav {
  margin-left: 150px;
  transition: all 0.5s ease;
}
.navbar-area.navbar-style-two nav .others-options {
  margin: 0 0 0 0;
}
.navbar-area.navbar-style-two nav .others-options .btn {
  color: #ffffff;
  border-color: #ffffff;
}
.navbar-area.navbar-style-two nav .others-options .btn:hover, .navbar-area.navbar-style-two nav .others-options .btn:focus {
  border-color: #ff2c9c;
  background: transparent;
}
.navbar-area.navbar-style-two.is-sticky .others-options .btn {
  color: #ff2c9c;
  border-color: #ff2c9c;
}
.navbar-area.navbar-style-two.is-sticky .others-options .btn:hover, .navbar-area.navbar-style-two.is-sticky .others-options .btn:focus {
  border-color: #ff2c9c;
  color: #ffffff;
}
.navbar-area.navbar-style-two.is-sticky nav .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.navbar-area.navbar-style-three {
  position: relative;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 15px;
}
.navbar-area.navbar-style-three nav .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.navbar-area.navbar-style-three nav .others-options {
  margin: 0 0 0 0;
}
.navbar-area.navbar-style-four {
  position: relative;
  padding-bottom: 15px;
}
.navbar-area.navbar-style-four nav .navbar-nav {
  margin-left: auto;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 2px;
  padding-top: 2px;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar-area.navbar-style-2 nav .navbar-brand {
  color: #ffffff;
}
.navbar-area.navbar-style-2 nav .navbar-brand .black-logo {
  display: none;
}
.navbar-area.navbar-style-2 nav .navbar-nav .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-area.navbar-style-2 nav .navbar-nav .nav-item a {
  color: #ffffff;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand {
  color: #18181b;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand .black-logo {
  display: block;
}
.navbar-area.navbar-style-2.is-sticky .navbar-brand .white-logo {
  display: none;
}
.navbar-area.navbar-style-2.is-sticky .navbar-nav > .nav-item > a {
  color: #18181b;
}
.navbar-area.navbar-style-3 .container {
  max-width: 1365px;
}
.navbar-area.bg-white {
  padding: 10px 0;
}
.navbar-area .container-fluid {
  max-width: 1920px;
  padding-left: 30px;
  padding-right: 30px;
}
.navbar-area.dark-navbar {
  background: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-area.dark-navbar .navbar-nav .nav-item a {
  color: #fff;
}
.navbar-area.dark-navbar .navbar-nav .nav-item a:hover, .navbar-area.dark-navbar .navbar-nav .nav-item a:focus, .navbar-area.dark-navbar .navbar-nav .nav-item a.active {
  color: #fff;
}
.navbar-area.dark-navbar.is-sticky {
  padding-bottom: 2px;
  padding-top: 2px;
  background-color: #000 !important;
}

.navbar-toggler {
  border: none;
  background: #eee !important;
  padding: 10px;
  border-radius: 0;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .icon-bar {
  width: 28px;
  transition: all 0.3s;
  background: #ff2c9c;
  height: 2px;
  display: block;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 6px 0;
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
  left: 0;
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
  left: 0;
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
  position: relative;
  z-index: 1;
  height: 800px;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.main-banner.gradient-banner {
  height: 800px;
}
.main-banner.gradient-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  opacity: 0.7;
}
.main-banner.gradient-banner .main-banner-content {
  text-align: center;
  max-width: 690px;
  margin: -40px auto 0;
}
.main-banner.gradient-banner .main-banner-content span {
  color: #ffffff;
}
.main-banner.gradient-banner .main-banner-content h1 {
  color: #ffffff;
}
.main-banner.gradient-banner .main-banner-content p {
  color: #ffffff;
  opacity: 0.9;
  max-width: 557px;
  margin: 0 auto 30px;
}
.main-banner.gradient-banner .main-banner-content .btn-primary:hover, .main-banner.gradient-banner .main-banner-content .btn-primary:focus {
  color: #ffffff;
  border-color: #ffffff;
}
.main-banner.gradient-banner .main-banner-content .btn-secondary {
  border-color: #ffffff;
  color: #ffffff;
}
.main-banner.gradient-banner .main-banner-content .btn-secondary:hover, .main-banner.gradient-banner .main-banner-content .btn-secondary:focus {
  border-color: #ff2c9c;
  background: transparent;
}
.main-banner.startup-agency .container {
  max-width: 1365px;
}
.main-banner.startup-agency .main-banner-content h1 {
  font-size: 50px;
}
.main-banner.startup-agency .main-banner-content p {
  margin-bottom: 30px;
}
.main-banner.startup-agency2 {
  background-color: #fbfbfb;
}

.main-banner-boxes .container-fluid {
  padding-left: 85px;
  padding-right: 85px;
}
.main-banner-boxes .main-banner .container-fluid {
  padding-right: 55px;
  padding-left: 55px;
}
.main-banner-boxes .main-banner .main-banner-content p {
  max-width: 520px;
}

.main-banner2 .container {
  margin-top: 50px;
}

.banner-animation-image2 {
  text-align: center;
}

/* Hero banner */
.hero-banner {
  position: relative;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-banner.overly::before {
  content: "";
  background-color: #18181b;
  opacity: 0.85;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.hero-banner.overly-6::before {
  content: "";
  background-color: #18181b;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.hero-banner .main-banner-content {
  position: relative;
  z-index: 5;
}
.hero-banner .main-banner-content.text-center {
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.hero-banner .main-banner-content.left {
  max-width: 800px;
}
.hero-banner .main-banner-content span {
  color: #ff2c9c;
}
.hero-banner .main-banner-content h1 {
  color: #ffffff;
  font-size: 80px;
}
.hero-banner .main-banner-content p {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 19px;
}
.hero-banner .main-banner-content .btn {
  margin-top: 15px;
}
.hero-banner .main-banner-content .btn-primary {
  font-size: 16px;
  padding: 15px 40px;
}
.hero-banner .main-banner-content .btn-primary:hover, .hero-banner .main-banner-content .btn-primary:focus {
  color: #ffffff;
  border-color: #ffffff;
}
.hero-banner .main-banner-content .btn-secondary {
  border-color: #ffffff;
  color: #ffffff;
  font-size: 16px;
  padding: 15px 30px;
}
.hero-banner .main-banner-content .btn-secondary:hover, .hero-banner .main-banner-content .btn-secondary:focus {
  border-color: #ff2c9c;
  background: transparent;
}
.hero-banner .animate-banner-image {
  margin-top: 50px;
}

#particles-js canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.creative-shape img {
  width: 100%;
}

/* Animate banner image */
.animate-banner-image {
  position: relative;
}
.animate-banner-image img {
  display: block !important;
  animation: border-transform 10s linear infinite alternate forwards;
  margin: auto;
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes opacitychange {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hero-slider .swiper-button-prev {
  left: 30px;
  background: #e5e5e5;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
}
.hero-slider .swiper-button-prev::after {
  color: #18181b;
  font-size: 20px;
  padding-right: 3px;
}
.hero-slider .swiper-button-next {
  right: 30px;
  background: #e5e5e5;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
}
.hero-slider .swiper-button-next::after {
  color: #18181b;
  font-size: 20px;
  padding-left: 3px;
}
.hero-slider .swiper-button-prev:hover, .hero-slider .swiper-button-next:hover {
  background-color: #ff2c9c;
}
.hero-slider .swiper-button-prev:hover::after, .hero-slider .swiper-button-next:hover::after {
  color: #fff;
}
.hero-slider .swiper-pagination {
  bottom: 30px;
}
.hero-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #D6D6D6;
  border: 1px solid #b7b7b7;
  opacity: 1;
}
.hero-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
}

.item-bg2 {
  background-image: url(../images/main-banner1.jpg);
}

.item-bg3 {
  background-image: url(../images/main-banner2.jpg);
}

.item-bg4 {
  background-image: url(../images/main-banner4.jpg);
}

.item-bg5 {
  background-position: right top;
  background-image: url(../images/main-banner-shape2.jpg);
}

.item-bg6 {
  background-position: right top;
  background-image: url(../images/main-banner5.jpg);
}

.item-bg7 {
  background-position: right top;
  background-image: url(../images/main-banner6.jpg);
}

.hero-bg1 {
  background-position: center center;
  background-image: url(../images/hero-banner1.jpg);
}

.hero-bg2 {
  background-position: center center;
  background-image: url(../images/hero-banner2.jpg);
}

.hero-bg3 {
  background-position: center center;
  background-image: url(../images/hero-banner3.jpg);
}

.hero-bg4 {
  background-position: center center;
  background-image: url(../images/hero-banner4.jpg);
}

.hero-bg5 {
  background-position: center center;
  background-image: url(../images/hero-banner5.jpg);
}

.hero-bg6 {
  background-position: center center;
  background-image: url(../images/hero-banner6.jpg);
}

.hero-bg7 {
  background-position: center center;
  background-image: url(../images/hero-banner7.jpg);
}

.hero-bg8 {
  background-position: center center;
  background-image: url(../images/hero-banner8.jpg);
}

.hero-bg9 {
  background-position: center center;
  background-image: url(../images/hero-banner9.jpg);
}

.hero-bg10 {
  background-position: center center;
  background-image: url(../images/hero-banner10.jpg);
}

.hero-bg11 {
  background-position: center center;
  background-image: url(../images/hero-banner11.jpg);
}

.hero-bg12 {
  background-position: center center;
  background-image: url(../images/hero-banner12.jpg);
}

.hero-bg13 {
  background-position: center center;
  background-image: url(../images/hero-banner13.jpg);
}

.hero-bg14 {
  background-position: center center;
  background-image: url(../images/hero-banner14.jpg);
}

.hero-bg15 {
  background-position: center center;
  background-image: url(../images/hero-banner15.jpg);
}

.clip-path-one {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 70% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 70% 100%, 0 90%);
}

.main-banner-content {
  margin-top: 30px;
  padding-right: 30px;
}
.main-banner-content span {
  color: #ff2c9c;
  display: block;
  font-weight: 600;
}
.main-banner-content h1 {
  font-size: 62px;
  font-weight: 800;
  margin: 12px 0 30px;
  text-transform: capitalize;
}
.main-banner-content p {
  margin-bottom: 30px;
  font-size: 16px;
}
.main-banner-content .btn-primary {
  margin-right: 20px;
}

.banner-image {
  text-align: center;
}

.banner-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
}
.banner-form form .form-control {
  padding-left: 15px;
}
.banner-form form .btn {
  display: block;
  width: 100%;
  transform: unset;
  margin-top: 25px;
}

.banner-animation-image {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.banner-animation-image img {
  position: absolute;
  text-align: center;
}
.banner-animation-image img:nth-child(1) {
  left: 120px;
  top: -72px;
  z-index: 2;
}
.banner-animation-image img:nth-child(2) {
  left: 170px;
  bottom: -130px;
  z-index: 2;
}
.banner-animation-image img:nth-child(3) {
  left: 0;
  bottom: 110px;
  right: -250px;
  margin: 0 auto;
  z-index: 2;
}
.banner-animation-image img:nth-child(4) {
  left: 38px;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  top: -67px;
}
.banner-animation-image img:nth-child(5) {
  left: 0;
  bottom: -15px;
  right: -250px;
  margin: 0 auto;
  z-index: 2;
}
.banner-animation-image img:nth-child(6) {
  left: 170px;
  bottom: 0;
  z-index: 1;
}
.banner-animation-image img:nth-child(7) {
  left: 73px;
  bottom: -15px;
}
.banner-animation-image img:nth-child(8) {
  left: -15px;
  top: -40px;
}
.banner-animation-image img:nth-child(9) {
  left: 0;
  right: -160px;
  margin: 0 auto;
  top: -115px;
}
.banner-animation-image img:nth-child(10) {
  right: 4px;
  bottom: 0;
  z-index: -1;
}
.banner-animation-image img:nth-child(11) {
  right: -70px;
  top: -44px;
  z-index: -2;
}
.banner-animation-image img:nth-child(12) {
  right: -75px;
  bottom: -100px;
  z-index: -1;
}
.banner-animation-image img.main-pic {
  display: none;
  position: relative;
}

.slider-area ul li {
  position: relative;
  z-index: 1;
}
.slider-area ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #18181b;
  opacity: 0.55;
  z-index: 1;
  visibility: visible;
}
.slider-area ul li .tp-caption.NotGeneric-Title, .slider-area ul li .NotGeneric-Title {
  font-family: "Saira", sans-serif !important;
}
.slider-area ul li .tp-caption.NotGeneric-SubTitle, .slider-area ul li .NotGeneric-SubTitle {
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 1.8 !important;
}
.slider-area ul li .tp-caption.NotGeneric-SubTitle-paragraph, .slider-area ul li .NotGeneric-SubTitle-paragraph {
  color: #ff2c9c !important;
  font-size: 18px !important;
}
.slider-area ul li .tp-caption.NotGeneric-btn .btn, .slider-area ul li .NotGeneric-btn .btn {
  margin: 0 4px;
  transition: all 0.5s ease !important;
}
.slider-area ul li .tp-caption.NotGeneric-btn .btn-primary, .slider-area ul li .NotGeneric-btn .btn-primary {
  background-color: #ffffff;
}
.slider-area ul li .tp-caption.NotGeneric-btn .btn-primary:hover, .slider-area ul li .tp-caption.NotGeneric-btn .btn-primary:focus, .slider-area ul li .NotGeneric-btn .btn-primary:hover, .slider-area ul li .NotGeneric-btn .btn-primary:focus {
  border-color: #ffffff;
}
.slider-area ul li .tp-caption.NotGeneric-btn .btn-secondary, .slider-area ul li .NotGeneric-btn .btn-secondary {
  border-color: #ffffff;
  color: #ffffff;
}
.slider-area ul li .tp-caption.NotGeneric-btn .btn-secondary:hover, .slider-area ul li .tp-caption.NotGeneric-btn .btn-secondary:focus, .slider-area ul li .NotGeneric-btn .btn-secondary:hover, .slider-area ul li .NotGeneric-btn .btn-secondary:focus {
  border-color: #ff2c9c;
  background: transparent;
}
.slider-area ul li .slotholder {
  filter: unset !important;
}

/* Video studio */
.video-studio {
  overflow: hidden;
}
.video-studio .video-box {
  position: relative;
}
.video-studio .video-box .video-btn {
  background-color: #ff2c9c;
  color: #ffffff;
  font-size: 50px;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.video-studio .video-box .video-btn::after, .video-studio .video-box .video-btn::before {
  border: 1px solid #ff2c9c;
}
.video-studio .video-box .video-btn:hover, .video-studio .video-box .video-btn:focus {
  background-color: #ffffff;
  color: #ff2c9c;
}
.video-studio .video-box .video-btn:hover::after, .video-studio .video-box .video-btn:hover::before, .video-studio .video-box .video-btn:focus::after, .video-studio .video-box .video-btn:focus::before {
  border-color: #ffffff;
}
.video-studio .video-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
}
.video-studio .video-background video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  -o-object-fit: cover;
     object-fit: cover;
}
.video-studio.center {
  text-align: center;
}
.video-studio.center .main-banner-content {
  padding-right: 0;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.video-studio.center .main-banner-content h1 {
  font-size: 70px;
}
.video-studio.center .video-box .video-btn {
  position: relative;
  transform: translateY(0);
  margin-top: 60px;
  cursor: pointer;
}

/*================================================
Featured Services CSS
=================================================*/
.featured-services-area {
  position: relative;
  z-index: 1;
  margin-top: -85px;
  margin-bottom: -30px;
}
.featured-services-area .featured-services-box {
  margin-top: 30px;
}
.featured-services-area.boxes-style-two {
  margin-top: 0;
  padding-top: 80px;
}

.featured-services-box {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  padding: 40px 30px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin-bottom: 30px;
}
.featured-services-box .icon {
  font-size: 45px;
  color: #ff2c9c;
  transition: all 0.5s ease;
}
.featured-services-box h3 {
  font-size: 24px;
  margin: 15px 0 15px;
  transition: all 0.5s ease;
}
.featured-services-box h3 a {
  color: #18181b;
}
.featured-services-box h3 a:hover {
  color: #ffffff;
}
.featured-services-box p {
  margin-bottom: 25px;
  transition: all 0.5s ease;
}
.featured-services-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.featured-services-box .back-text {
  font-size: 200px;
  position: absolute;
  font-weight: 700;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  top: 0;
  color: #010101;
  opacity: 0.04;
  z-index: -1;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
.featured-services-box:hover::before, .featured-services-box:focus::before, .featured-services-box.active::before {
  opacity: 1;
  visibility: visible;
}
.featured-services-box:hover .icon, .featured-services-box:focus .icon, .featured-services-box.active .icon {
  color: #ffffff;
}
.featured-services-box:hover p, .featured-services-box:focus p, .featured-services-box.active p {
  color: #ffffff;
}
.featured-services-box:hover h3, .featured-services-box:focus h3, .featured-services-box.active h3 {
  color: #ffffff;
}
.featured-services-box:hover h3 a, .featured-services-box:focus h3 a, .featured-services-box.active h3 a {
  color: #ffffff;
}
.featured-services-box:hover h3 a:hover, .featured-services-box:focus h3 a:hover, .featured-services-box.active h3 a:hover {
  text-decoration: underline;
}
.featured-services-box:hover .read-more-btn, .featured-services-box:focus .read-more-btn, .featured-services-box.active .read-more-btn {
  color: #ffffff;
}
.featured-services-box:hover .back-text, .featured-services-box:focus .back-text, .featured-services-box.active .back-text {
  opacity: 0.1;
}

.read-more-btn {
  color: #18181b;
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-weight: 600;
}
.read-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
  width: 50px;
  height: 2px;
  background: #18181b;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.read-more-btn:hover::before, .read-more-btn:focus::before {
  opacity: 1;
  visibility: visible;
  left: 40px;
}
.read-more-btn i {
  padding-left: 5px;
}

.featured-services-slides .swiper-button-prev {
  left: 0;
  top: 30px;
  background: #e5e5e5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.featured-services-slides .swiper-button-prev::after {
  color: #18181b;
  font-size: 18px;
  padding-right: 3px;
}
.featured-services-slides .swiper-button-next {
  right: 0;
  top: 30px;
  background: #e5e5e5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.featured-services-slides .swiper-button-next::after {
  color: #18181b;
  font-size: 18px;
  padding-left: 3px;
}
.featured-services-slides .swiper-button-prev:hover, .featured-services-slides .swiper-button-next:hover {
  background-color: #ff2c9c;
}
.featured-services-slides .swiper-button-prev:hover::after, .featured-services-slides .swiper-button-next:hover::after {
  color: #fff;
}

/*==========================================
About CSS
===========================================*/
.about-content .section-title {
  text-align: left;
  margin-bottom: 18px;
  max-width: 100%;
}
.about-content .section-title p {
  margin: 0;
}

.about-inner-area {
  margin-top: 60px;
}
.about-inner-area .about-text h3 {
  margin-bottom: 12px;
  font-size: 22px;
}

.about-image {
  position: relative;
}
.about-image img {
  width: 100%;
  height: 600px;
}

.about-content-two {
  padding-left: 30px;
}
.about-content-two .about-text {
  margin-bottom: 30px;
}
.about-content-two .about-text:last-child {
  margin-bottom: 0;
}
.about-content-two .about-text h4 {
  font-size: 22px;
}
.about-content-two .about-text ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}
.about-content-two .about-text ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
  line-height: 1.8;
  font-weight: 600;
}
.about-content-two .about-text ul li:last-child {
  margin-bottom: 0;
}
.about-content-two .about-text ul li i {
  position: absolute;
  left: 0;
  top: 6px;
  color: #ff2c9c;
  font-size: 16px;
}

.video-box {
  text-align: center;
}
.video-box .video-btn {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  width: 90px;
  height: 90px;
  background-color: #f9004d;
  border-radius: 50%;
  color: #fff;
  font-size: 40px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.video-box .video-btn::after, .video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #f9004d;
  transition: 0.5s;
}
.video-box .video-btn i {
  padding-left: 8px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.video-box .video-btn::before {
  animation: ripple 2s linear infinite;
}
.video-box .video-btn::after {
  animation: ripple 2s linear 1s infinite;
}
.video-box .video-btn:hover, .video-box .video-btn:focus {
  background-color: #ff2c9c;
  color: #ffffff;
}
.video-box .video-btn:hover::after, .video-box .video-btn:hover::before, .video-box .video-btn:focus::after, .video-box .video-btn:focus::before {
  border-color: #ff2c9c;
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Case Studies CSS
=================================================*/
.case-studies-area .view-more-work {
  text-align: center;
  margin-top: 20px;
}
.case-studies-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1920px;
}

.single-case-studies {
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  min-height: 450px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.single-case-studies .content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  background: transparent;
  z-index: 2;
}
.single-case-studies .content span {
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.single-case-studies .content span a {
  color: #ffffff;
}
.single-case-studies .content span a:hover, .single-case-studies .content span a:focus {
  color: #ffffff;
}
.single-case-studies .content h3 {
  font-size: 25px;
  line-height: 1.3;
  margin: 10px 0 0;
  color: #ffffff;
  font-weight: 600;
}
.single-case-studies .content h3 a {
  color: #ffffff;
}
.single-case-studies .content h3 a:hover {
  text-decoration: underline;
}
.single-case-studies .btn {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 2;
}
.single-case-studies .shape {
  transition: all 0.5s ease;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.single-case-studies .btn-primary {
  border-color: #ffffff;
  color: #18181b;
  font-size: 14px;
}
.single-case-studies .btn-primary::before {
  background: #ffffff;
}
.single-case-studies .btn-primary:not(:disabled):not(.disabled).active:focus, .single-case-studies .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .single-case-studies .btn-primary.dropdown-toggle:focus {
  color: #ffffff;
}
.single-case-studies .btn-primary:hover, .single-case-studies .btn-primary:focus {
  color: #ffffff;
}
.single-case-studies:hover .shape {
  top: 55%;
}
.single-case-studies.text-center .btn {
  left: 30px;
  right: 30px;
}

.mt-40:nth-child(1) .single-case-studies, .mt-40:nth-child(3) .single-case-studies {
  margin-top: 40px;
}
.mt-40:nth-child(5) .single-case-studies, .mt-40:nth-child(8) .single-case-studies, .mt-40:nth-child(11) .single-case-studies, .mt-40:nth-child(14) .single-case-studies, .mt-40:nth-child(17) .single-case-studies {
  margin-top: -40px;
}

.case-studies-tabs .react-tabs .react-tabs__tab-list {
  text-align: center;
  padding: 0 0 10px;
  border-bottom: 2px solid #f1f0f0;
  margin-bottom: 50px;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li {
  display: inline-block;
  transition: all 0.5s ease;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #646464;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 0;
  transition: all 0.5s ease;
  height: 2px;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li:hover, .case-studies-tabs .react-tabs .react-tabs__tab-list li:focus, .case-studies-tabs .react-tabs .react-tabs__tab-list li:focus-visible {
  color: #ff2c9c;
  outline: 0;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li:hover::before, .case-studies-tabs .react-tabs .react-tabs__tab-list li:focus::before, .case-studies-tabs .react-tabs .react-tabs__tab-list li:focus-visible::before {
  width: 100%;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li.react-tabs__tab--selected {
  color: #ff2c9c;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li.react-tabs__tab--selected::before {
  width: 100%;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li:first-child {
  margin-left: 0;
}
.case-studies-tabs .react-tabs .react-tabs__tab-list li:last-child {
  margin-right: 0;
}

.work-card {
  margin-bottom: 30px;
  position: relative;
  transition: all 0.5s ease;
}
.work-card::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  border-radius: 8px;
  transition: all 0.5s ease;
  z-index: 1;
}
.work-card:hover::before {
  background-color: rgba(0, 0, 0, 0.8);
}
.work-card img {
  border-radius: 8px;
  width: 100%;
}
.work-card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 30px;
  transition: all 0.5s ease;
  z-index: 5;
}
.work-card .content span {
  display: block;
  margin-bottom: 10px;
}
.work-card .content span a {
  color: #c6c9d8;
  font-weight: 600;
}
.work-card .content h3 {
  font-size: 24px;
  line-height: 1.4;
}
.work-card .content .custom-btn {
  margin-top: 20px;
}
.work-card .content a {
  color: #ffffff;
}
.work-card:hover .content {
  bottom: 20px;
}

.custom-btn {
  background-color: #fff;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 6px;
  color: #ff2c9c !important;
}
.custom-btn:hover {
  background-color: #ff2c9c;
  color: #ffffff !important;
}

.work-slides .swiper-button-prev {
  top: 30px;
  left: 10px;
  background: #e5e5e5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.work-slides .swiper-button-prev::after {
  color: #18181b;
  font-size: 20px;
  padding-right: 3px;
}
.work-slides .swiper-button-next {
  top: 30px;
  right: 10px;
  background: #e5e5e5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.work-slides .swiper-button-next::after {
  color: #18181b;
  font-size: 20px;
  padding-left: 3px;
}
.work-slides .swiper-button-prev:hover, .work-slides .swiper-button-next:hover {
  background-color: #ff2c9c;
}
.work-slides .swiper-button-prev:hover::after, .work-slides .swiper-button-next:hover::after {
  color: #fff;
}
.work-slides .swiper-pagination {
  position: initial;
}
.work-slides .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #D6D6D6;
  border: 1px solid #b7b7b7;
  opacity: 1;
}
.work-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
}

/*================================================
Quotes CSS
=================================================*/
.quotes-area {
  padding-top: 100px;
  padding-bottom: 80px;
}

.quotes-content {
  text-align: center;
}
.quotes-content p {
  margin: 0 auto;
  color: #18181b;
  max-width: 755px;
  line-height: 45px;
  position: relative;
  font-size: 32px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
}
.quotes-content p::before {
  content: "";
  width: 211px;
  height: 154px;
  display: inline-block;
  position: absolute;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/left-quote.png);
  left: -150px;
  top: 0;
}
.quotes-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 25px;
  color: #ff2c9c;
}
.quotes-content span {
  display: block;
  color: #646464;
  font-size: 14px;
}

/*================================================
Services CSS
=================================================*/
.services-area .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.services-area.services-section {
  padding-bottom: 80px;
}

.services-inner {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  padding-top: 110px;
  width: 100%;
  height: 100%;
}
.services-inner .services-section-title {
  max-width: 525px;
  padding-right: 60px;
  margin-left: auto;
  padding-left: 15px;
}
.services-inner .services-section-title h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 36px;
}
.services-inner .services-section-title .bar {
  position: relative;
  width: 55px;
  height: 2px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}
.services-inner .services-section-title p {
  color: #ffffff;
  opacity: 0.95;
  margin-bottom: 25px;
}

.services-slides .swiper-button-next {
  right: 0;
}
.services-slides .swiper-button-prev {
  left: 0;
}
.services-slides .swiper-button-next:after, .services-slides .swiper-button-prev:after {
  color: #ff2c9c;
  font-size: 30px;
}

.services-list {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 70px;
  background-image: url(../images/services-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.services-list::before {
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: #2b2929;
  opacity: 0.2;
  z-index: -1;
  content: "";
}
.services-list .single-services {
  margin-bottom: 0;
}

.single-services {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 0;
}
.single-services::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #18181b;
  opacity: 0.7;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.single-services:hover::before {
  opacity: 0.9;
}
.single-services .content {
  position: absolute;
  bottom: -145px;
  left: 0;
  text-align: center;
  padding: 20px 20px 35px;
  z-index: 2;
  transition: 0.8s;
}
.single-services .content .icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  font-size: 30px;
  color: #ffffff;
  display: inline-block;
  border: 1px solid #ff2c9c;
  line-height: 65px;
  z-index: 1;
  position: relative;
  transition: all 0.5s ease;
}
.single-services .content .icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.single-services .content h3 {
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 15px;
  color: #ffffff;
}
.single-services .content p {
  color: #ffffff;
  opacity: 0.95;
  margin-bottom: 15px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.single-services .content .read-more-btn {
  color: #ffffff;
  visibility: hidden;
  opacity: 0;
}
.single-services .content .read-more-btn:hover, .single-services .content .read-more-btn:focus {
  color: #ff2c9c;
}
.single-services:hover .content, .single-services:focus .content {
  bottom: 0;
}
.single-services:hover .content .icon::before, .single-services:focus .content .icon::before {
  width: 100%;
  height: 100%;
}
.single-services:hover .content p, .single-services:focus .content p {
  opacity: 1;
  visibility: visible;
}
.single-services:hover .content a, .single-services:focus .content a {
  opacity: 1;
  visibility: visible;
}

.bg-13c4a1 {
  background-color: #13c4a1;
}

.bg-6610f2 {
  background-color: #6610f2;
}

.bg-ffb700 {
  background-color: #ffb700;
}

.bg-fc3549 {
  background-color: #fc3549;
}

.bg-00d280 {
  background-color: #00d280;
}

.bg-ff612f {
  background-color: #ff612f;
}

/* Service card one */
.service-card-one {
  margin-bottom: 30px;
  transition: all 0.5s ease;
  height: 500px;
}
.service-card-one i {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 35px;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
  margin-bottom: 25px;
  display: inline-block;
  transition: all 0.5s ease;
}
.service-card-one h3 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}
.service-card-one h3 a {
  color: #18181b;
}
.service-card-one h3 a:hover {
  color: #ff2c9c;
}
.service-card-one p {
  margin: 0;
}
.service-card-one.white-bg {
  background-color: #ffffff;
  padding: 40px 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
}
.service-card-one.white-bg:hover {
  background-color: #ff2c9c;
  margin-top: -5px;
}
.service-card-one.white-bg:hover i {
  background-color: #ffffff !important;
  color: #ff2c9c;
}
.service-card-one.white-bg:hover h3 {
  color: #ffffff;
}
.service-card-one.white-bg:hover h3 a {
  color: #ffffff;
}
.service-card-one.white-bg:hover h3 a:hover {
  text-decoration: underline;
}
.service-card-one.white-bg:hover p {
  color: #ffffff;
}
.service-card-one.bg-fcfbfb {
  padding: 45px 30px;
  border: 1px solid #f1f1f1;
}
.service-card-one.bg-fcfbfb:hover {
  margin-top: -5px;
}
.service-card-one .read-more {
  color: #ff2c9c;
  display: inline-block;
  margin-top: 15px;
}
.service-card-one.center {
  text-align: center;
  padding: 0 15px;
}
.service-card-one.border.text-center {
  border-radius: 10px;
}

/* Service style two */
.service-style-two .container {
  max-width: 1400px;
  width: 100%;
}
.service-style-two .service-info.pr-50 {
  padding-right: 50px;
}
.service-style-two .service-info.pl-50 {
  padding-left: 50px;
}
.service-style-two .service-info .info-head {
  margin-bottom: 30px;
}
.service-style-two .service-info .title {
  font-size: 40px;
  margin-bottom: 15px;
}
.service-style-two .service-info .btn {
  margin-top: 10px;
}

.feature-list {
  background-color: #ffffff;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 13px 13px 13px 45px;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  font-weight: 600;
  font-size: 15px;
  border: 1px solid #f2effc;
  cursor: pointer;
  transition: all 0.5s ease;
}
.feature-list i {
  position: absolute;
  left: 18px;
  top: 14px;
  color: #ff2c9c;
  font-size: 20px;
}
.feature-list:hover {
  background-color: #ff2c9c;
  color: #ffffff;
  margin-left: 2px;
}
.feature-list:hover i {
  color: #ffffff;
}

/*================================================
Portfolio Details CSS
=================================================*/
.services-details-image {
  position: relative;
}

.services-details-desc {
  margin-top: 50px;
}
.services-details-desc h1 {
  font-size: 30px;
  margin-bottom: 15px;
}
.services-details-desc h2 {
  font-size: 26px;
  margin-bottom: 15px;
}
.services-details-desc h3 {
  font-size: 24px;
  margin-bottom: 15px;
}
.services-details-desc h4 {
  font-size: 20px;
  margin-bottom: 15px;
}
.services-details-desc ul, .services-details-desc ol {
  padding-left: 17px;
}
.services-details-desc ul li, .services-details-desc ol li {
  line-height: 1.8;
  margin-bottom: 8px;
}
.services-details-desc ul li:last-child, .services-details-desc ol li:last-child {
  margin-bottom: 0;
}

.services-details-info {
  margin-top: 50px;
}
.services-details-info h3 {
  font-size: 26px;
  margin-bottom: 15px;
}
.services-details-info ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.services-details-info ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  color: #646464;
  line-height: 1.8;
}
.services-details-info ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/*================================================
Partner CSS
=================================================*/
.single-partner {
  text-align: center;
  margin-bottom: 40px;
}
.single-partner img {
  transition: all 0.5s ease;
}
.single-partner:hover img, .single-partner:focus img {
  opacity: 0.5;
}
.single-partner.border {
  margin-bottom: 30px;
  height: 130px;
}

/*================================================
Feedback CSS
=================================================*/
.feedback-area {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/feedback-bg.jpg);
}
.feedback-area.feedback-style-3 {
  background-color: #263238;
  background-image: none;
}
.feedback-area.feedback-style-3 .single-feedback p, .feedback-area.feedback-style-3 .single-feedback h3, .feedback-area.feedback-style-3 .single-feedback span {
  color: #fff;
}

.feedback-list {
  text-align: center;
}
.feedback-list .quotes-icon {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  margin: 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 65px;
  margin-bottom: 35px;
}
.feedback-list .quotes-icon img {
  width: 50px;
}

.single-feedback {
  text-align: center;
  max-width: 755px;
  margin: 0 auto;
}
.single-feedback p {
  margin: 0;
  color: #18181b;
  font-size: 25px;
  font-weight: 600;
  font-style: italic;
}
.single-feedback .bar {
  position: relative;
  margin: 20px auto;
  height: 35px;
  width: 2px;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
}
.single-feedback h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.single-feedback span {
  display: block;
  color: #646464;
  font-size: 14px;
}
.single-feedback .rating {
  margin-bottom: 15px;
}
.single-feedback .rating i {
  font-size: 25px;
  margin: 0 3px;
  color: #FF9529;
}

.feedback-slides .swiper-pagination {
  position: initial;
  margin-top: 20px;
}
.feedback-slides .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #D6D6D6;
  border: 1px solid #b7b7b7;
  opacity: 1;
}
.feedback-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
}

.client-image-box img {
  position: absolute;
}
.client-image-box img:nth-child(1) {
  bottom: 0;
  right: 20%;
}
.client-image-box img:nth-child(2) {
  left: 25px;
  top: 25px;
}
.client-image-box img:nth-child(3) {
  left: 17%;
  top: 3%;
}
.client-image-box img:nth-child(4) {
  right: 18%;
  top: 8%;
}
.client-image-box img:nth-child(5) {
  right: 0;
  top: 0;
}
.client-image-box img:nth-child(6) {
  bottom: 3%;
  left: 8%;
}
.client-image-box img:nth-child(7) {
  bottom: 5%;
  left: 25%;
}
.client-image-box img:nth-child(8) {
  right: 4%;
  bottom: 13%;
}

/*================================================
Portfolio CSS
=================================================*/
.single-portfolio {
  position: relative;
}
.single-portfolio::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #18181b;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.single-portfolio .portfolio-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ff2c9c;
  z-index: 2;
  margin: 20px;
  padding: 25px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.single-portfolio .portfolio-content h3 {
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  position: absolute;
  left: 0;
  transition: all 0.5s ease;
  right: 0;
  top: 60%;
  transform: translateY(-50%);
}
.single-portfolio .portfolio-content h3 a {
  color: #ffffff;
}
.single-portfolio .portfolio-content h3 a:hover {
  color: #ff2c9c;
}
.single-portfolio .portfolio-content .link-btn, .single-portfolio .portfolio-content .popup-btn {
  color: #ffffff;
  font-size: 20px;
  position: absolute;
  bottom: 15px;
}
.single-portfolio .portfolio-content .link-btn:hover, .single-portfolio .portfolio-content .link-btn:focus, .single-portfolio .portfolio-content .popup-btn:hover, .single-portfolio .portfolio-content .popup-btn:focus {
  color: #ff2c9c;
}
.single-portfolio .portfolio-content .link-btn {
  left: 0;
}
.single-portfolio .portfolio-content .popup-btn {
  right: 0;
}
.single-portfolio:hover::before, .single-portfolio:focus::before {
  opacity: 0.71;
  visibility: visible;
}
.single-portfolio:hover .portfolio-content, .single-portfolio:focus .portfolio-content {
  opacity: 1;
  visibility: visible;
}
.single-portfolio:hover .portfolio-content h3, .single-portfolio:focus .portfolio-content h3 {
  top: 50%;
}
.single-portfolio:hover .portfolio-content .link-btn, .single-portfolio:focus .portfolio-content .link-btn {
  left: 15px;
}
.single-portfolio:hover .portfolio-content .popup-btn, .single-portfolio:focus .portfolio-content .popup-btn {
  right: 15px;
}

/*================================================
Team CSS
=================================================*/
.team-area {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}
.team-area::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/feedback-bg.jpg);
}

.single-team {
  margin-bottom: 30px;
}
.single-team .team-img {
  position: relative;
}
.single-team .team-img::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  margin: 15px;
  background: #18181b;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.single-team .team-img img {
  width: 100%;
}
.single-team .team-img .overlay {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  padding-left: 15px;
  padding-right: 15px;
}
.single-team .team-img .overlay p {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.single-team .team-img .overlay p span {
  display: block;
}
.single-team .team-img .overlay ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-team .team-img .overlay ul li {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}
.single-team .team-img .overlay ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  color: #ffffff;
  border: 1px solid #ffffff;
  position: relative;
  z-index: 1;
}
.single-team .team-img .overlay ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  width: 0;
  height: 0;
  z-index: -1;
  transition: all 0.5s ease;
}
.single-team .team-img .overlay ul li a:hover, .single-team .team-img .overlay ul li a:focus {
  border-color: transparent;
}
.single-team .team-img .overlay ul li a:hover::before, .single-team .team-img .overlay ul li a:focus::before {
  width: 100%;
  height: 100%;
}
.single-team .team-content {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  padding: 25px 30px;
}
.single-team .team-content h3 {
  font-size: 21px;
  color: #ffffff;
  margin-bottom: 6px;
}
.single-team .team-content span {
  color: #ffffff;
  opacity: 0.9;
  font-size: 14px;
}
.single-team:hover .team-img::before, .single-team:focus .team-img::before {
  opacity: 0.77;
  visibility: visible;
}
.single-team:hover .team-img .overlay, .single-team:focus .team-img .overlay {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.team-card {
  margin-bottom: 30px;
  transition: all 0.5s ease;
}
.team-card img {
  width: 100%;
}
.team-card .team-caption {
  margin-top: 25px;
}
.team-card .team-caption h3 {
  font-size: 24px;
  font-weight: 600;
}
.team-card .team-caption p {
  margin: 0;
  font-size: 15px;
}
.team-card .team-caption ul {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0;
}
.team-card .team-caption ul li {
  display: inline-block;
  margin: 0 3px;
}
.team-card .team-caption ul li i {
  background-color: #f1f1f1;
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #18181b;
  transition: all 0.5s ease;
}
.team-card .team-caption ul li i:hover {
  background-color: #ff2c9c;
  color: #ffffff;
}
.team-card .team-img {
  position: relative;
}
.team-card .team-img .social-links {
  position: absolute;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;
  opacity: 0.95;
  transform: scale(0);
  transition: all 0.5s ease;
}
.team-card .team-img ul {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0;
}
.team-card .team-img ul li {
  display: inline-block;
  margin: 0 3px;
}
.team-card .team-img ul li i {
  background-color: #f1f1f1;
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #18181b;
  transition: all 0.5s ease;
}
.team-card .team-img ul li i:hover {
  background-color: #ff2c9c;
  color: #ffffff;
}
.team-card:hover .team-img .social-links {
  height: 100%;
  transform: scale(1);
}

.team-slider .swiper-button-prev {
  background-color: #18181b;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.team-slider .swiper-button-prev::after {
  color: #fff;
  font-size: 20px;
  padding-right: 3px;
}
.team-slider .swiper-button-next {
  background-color: #18181b;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
}
.team-slider .swiper-button-next::after {
  color: #fff;
  font-size: 20px;
  padding-left: 3px;
}

/*==========================================
Pricing CSS
===========================================*/
.pricing-area {
  position: relative;
  z-index: 1;
}

.pricing-table {
  text-align: center;
  background: #ffffff;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding-bottom: 35px;
  transition: all 0.5s ease;
}
.pricing-table .pricing-header {
  background: #ffffff;
  position: relative;
  padding: 30px 20px;
  transition: all 0.5s ease;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #eeeeee;
}
.pricing-table .pricing-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  z-index: -1;
}
.pricing-table .pricing-header h3 {
  font-size: 20px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  margin-bottom: 0;
}
.pricing-table .price {
  margin-bottom: 20px;
  font-weight: bold;
}
.pricing-table .price span {
  color: #ff2c9c;
  font-size: 40px;
}
.pricing-table .price span sup {
  font-weight: 500;
}
.pricing-table .price span span {
  font-size: 14px;
  font-weight: 500;
}
.pricing-table .pricing-features ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 30px;
}
.pricing-table .pricing-features ul li {
  font-size: 16px;
  margin-bottom: 12px;
}
.pricing-table .pricing-features ul li:last-child {
  margin-bottom: 0;
}
.pricing-table .pricing-features ul li.active {
  color: #646464;
  text-decoration: none;
}
.pricing-table:hover {
  transform: translateY(-10px);
}
.pricing-table:hover .pricing-header::before {
  opacity: 1;
  visibility: visible;
}
.pricing-table:hover .pricing-header h3 {
  color: #ffffff;
}
.pricing-table.active-plan .pricing-header::before {
  opacity: 1;
  visibility: visible;
}
.pricing-table.active-plan .pricing-header h3 {
  color: #ffffff;
}
.pricing-table.active-plan .btn-primary::after, .pricing-table.active-plan .btn-primary::before {
  background: #ff2c9c;
}

/*================================================
Pricing Area CSS
=================================================*/
.single-pricing-table {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding-bottom: 30px;
  border-radius: 5px;
  transition: all 0.5s ease;
}
.single-pricing-table .pricing-header {
  padding: 20px 40px;
}
.single-pricing-table .pricing-header h3 {
  margin-bottom: 0;
  font-size: 22px;
}
.single-pricing-table .price {
  color: #18181b;
  transition: all 0.5s ease;
  padding: 10px 40px;
  border-top: 2px dashed #f4f5fe;
  border-bottom: 2px dashed #f4f5fe;
  font-size: 40px;
  font-weight: bold;
}
.single-pricing-table .price sup {
  top: -18px;
  font-size: 18px;
  font-weight: 500;
}
.single-pricing-table .price sub {
  bottom: 2px;
  color: #4a6f8a;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 500;
}
.single-pricing-table .price .info-text {
  font-weight: normal;
}
.single-pricing-table .pricing-features {
  margin: 0;
  padding: 30px 40px;
  list-style-type: none;
}
.single-pricing-table .pricing-features li {
  margin-bottom: 10px;
  position: relative;
  color: #444683;
  padding-left: 25px;
  font-size: 16px;
}
.single-pricing-table .pricing-features li:last-child {
  margin-bottom: 0;
}
.single-pricing-table .pricing-features li i {
  color: #ff2c9c;
  position: absolute;
  left: 0;
  top: 3px;
}
.single-pricing-table .pricing-features li span {
  display: inline-block;
  color: #b7bad2;
  font-size: 20px;
  position: absolute;
  top: 1px;
  margin-left: 3px;
}
.single-pricing-table .btn-box {
  padding: 0 40px;
}
.single-pricing-table:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  transform: translateY(-10px);
}

.pricing-list-tab .react-tabs .react-tabs__tab-list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 40px;
  border: none;
  text-align: center;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li {
  display: inline-block;
  background-color: #ffffff;
  color: #8d8fb4;
  padding: 10px 30px 10px 53px;
  position: relative;
  border-radius: 5px;
  margin-left: -1px;
  margin-right: -1px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li i {
  color: #ff2c9c;
  margin-right: 2px;
  position: absolute;
  font-size: 18px;
  top: 48%;
  transform: translateY(-48%);
  left: 30px;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li.react-tabs__tab--selected, .pricing-list-tab .react-tabs .react-tabs__tab-list li:focus, .pricing-list-tab .react-tabs .react-tabs__tab-list li:focus-visible {
  color: #ffffff;
  background-color: #ff2c9c;
  outline: 0;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li.react-tabs__tab--selected i, .pricing-list-tab .react-tabs .react-tabs__tab-list li:focus i, .pricing-list-tab .react-tabs .react-tabs__tab-list li:focus-visible i {
  color: #ffffff;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li:nth-child(1) {
  border-radius: 30px 0 0 30px;
}
.pricing-list-tab .react-tabs .react-tabs__tab-list li:nth-child(2) {
  border-radius: 0 30px 30px 0;
}

/*================================================
Our Work Process CSS
=================================================*/
.work-process-area {
  overflow: hidden;
}
.work-process-area .section-title {
  margin-bottom: 70px;
}

.work-process {
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 995px;
  margin: 0 auto;
}
.work-process img.circle-image {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -10px;
  z-index: -1;
}

.work-process-list .single-work-process {
  position: absolute;
  left: 0;
  top: 0;
}
.work-process-list .single-work-process:nth-child(1) {
  left: 10%;
  top: 20px;
}
.work-process-list .single-work-process:nth-child(2) {
  left: auto;
  right: 10%;
  top: 20px;
}
.work-process-list .single-work-process:nth-child(2) .icon {
  background: transparent;
  color: #07ee7f;
}
.work-process-list .single-work-process:nth-child(2) .icon::before {
  background: #07ee7f;
}
.work-process-list .single-work-process:nth-child(3) {
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}
.work-process-list .single-work-process:nth-child(3) .icon {
  background: transparent;
  color: #ff650b;
}
.work-process-list .single-work-process:nth-child(3) .icon::before {
  background: #ff650b;
}
.work-process-list .single-work-process:nth-child(4) {
  left: auto;
  right: 10%;
  bottom: 5%;
  top: auto;
}
.work-process-list .single-work-process:nth-child(4) .icon {
  background: transparent;
  color: #9333ff;
}
.work-process-list .single-work-process:nth-child(4) .icon::before {
  background: #9333ff;
}
.work-process-list .single-work-process:nth-child(5) {
  left: 10%;
  top: auto;
  bottom: 5%;
}
.work-process-list .single-work-process:nth-child(5) .icon {
  background: transparent;
  color: #fcaf1c;
}
.work-process-list .single-work-process:nth-child(5) .icon::before {
  background: #fcaf1c;
}
.work-process-list .single-work-process:nth-child(6) {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.work-process-list .single-work-process:nth-child(6) .icon {
  background: transparent;
  color: #fc23d1;
}
.work-process-list .single-work-process:nth-child(6) .icon::before {
  background: #fc23d1;
}

.single-work-process .icon {
  background: transparent;
  color: #ff3389;
  width: 95px;
  height: 95px;
  line-height: 102px;
  font-size: 40px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.single-work-process .icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #ff3389;
  border-radius: 50%;
  opacity: 0.18;
}
.single-work-process h3 {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 5px;
}
.single-work-process span {
  display: block;
  color: #646464;
  font-size: 14px;
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*================================================
CTA CSS
=================================================*/
.cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 80px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/feedback-bg.jpg);
}
.cta-area.bg-gradient {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00) !important;
}
.cta-area.bg-gradient .cta-content h3 {
  color: #ffffff;
}
.cta-area.bg-gradient .cta-content p {
  color: #ffffff;
  opacity: 0.9;
}
.cta-area.bg-gradient .cta-btn-box .btn-primary {
  color: #ff2c9c;
  padding: 15px 35px;
  font-size: 16px;
}
.cta-area.bg-gradient .cta-btn-box .btn-primary::before {
  background: #ffffff;
}
.cta-area.bg-gradient .cta-btn-box .btn-primary:hover, .cta-area.bg-gradient .cta-btn-box .btn-primary:focus {
  color: #ffffff;
  border-color: #ffffff;
}

.cta-content h3 {
  margin-bottom: 5px;
  font-size: 50px;
  font-weight: 800;
}
.cta-content p {
  font-size: 18px;
  margin: 0;
}

.cta-btn-box {
  text-align: right;
}

.cta-area-two {
  background-image: url("../images/cta-bg.jpg");
  background-position: center center;
  background-size: cover;
  text-align: center;
  position: relative;
  background-attachment: fixed;
}
.cta-area-two::before {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  opacity: 0.95;
}
.cta-area-two .cta-content {
  position: relative;
  max-width: 850px;
  margin: auto;
  width: 100%;
}
.cta-area-two .cta-content span {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
}
.cta-area-two .cta-content h3 {
  color: #ffffff;
  font-size: 70px;
  text-transform: capitalize;
}
.cta-area-two .cta-content p {
  color: #ffffff;
}
.cta-area-two .cta-btn-box {
  text-align: center;
  margin-top: 40px;
  position: relative;
}
.cta-area-two .cta-btn-box .custom-btn {
  padding: 15px 35px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.cta-area-two .cta-btn-box .custom-btn:hover {
  background-color: #18181b;
}
.cta-area-two.dark-style::before {
  background: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  opacity: 0.95;
}
.cta-area-two.dark-style .cta-btn-box {
  margin-top: 30px;
}
.cta-area-two.dark-style .default-btn-two:hover {
  border: 2px solid #c90f48;
  background: #c90f48;
}

/*================================================
Fun Facts CSS
=================================================*/
.funfacts-area {
  position: relative;
  z-index: 1;
}
.funfacts-area .map-bg {
  position: absolute;
  z-index: -1;
  top: 120px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.funfact {
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  padding: 30px 0 25px;
  border-radius: 5px;
  transition: all 0.5s ease;
}
.funfact i {
  font-size: 30px;
  color: #ff2c9c;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  position: relative;
  margin-bottom: 20px;
  background: #F6F4FD;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
}
.funfact h3 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 6px;
}
.funfact:hover {
  margin-top: -5px;
}

.contact-cta-box {
  margin: 70px auto 0;
  max-width: 800px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;
  background-color: #ffffff;
}
.contact-cta-box h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}
.contact-cta-box p {
  margin-bottom: 0;
}
.contact-cta-box .btn {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.funfact-card {
  margin-bottom: 30px;
  text-align: center;
}
.funfact-card i {
  font-size: 60px;
  line-height: 1;
  margin-bottom: 20px;
  display: inline-block;
  color: #ff2c9c;
}
.funfact-card h3 {
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 15px;
}
.funfact-card h3 span {
  position: absolute;
  font-size: 35px;
  margin-left: 3px;
}
.funfact-card p {
  margin: 0;
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
  text-align: center;
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
  background-attachment: fixed;
  background-image: url(../images/feedback-bg.jpg);
}

.newsletter {
  max-width: 750px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.newsletter h4 {
  text-transform: capitalize;
  margin-bottom: 35px;
  font-size: 35px;
}
.newsletter form {
  position: relative;
}
.newsletter form .form-control {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border: none;
  height: 75px;
  border-radius: 50px;
  padding-left: 20px;
  font-size: 16px;
  background-color: #ffffff;
}
.newsletter form .btn {
  position: absolute;
  right: 7px;
  top: 8px;
  height: 60px;
  width: 175px;
  border-radius: 30px;
}
.newsletter form .btn::before {
  border-radius: 30px;
}
.newsletter form .validation-danger {
  margin-top: 20px;
  color: red;
}
.newsletter form .validation-success {
  margin-top: 20px;
}

.newsletter-inner-area {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  padding: 80px 30px;
  text-align: center;
}
.newsletter-inner-area::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  opacity: 0.9;
}
.newsletter-inner-area .newsletter-content {
  position: relative;
  max-width: 660px;
  margin: auto;
}
.newsletter-inner-area .newsletter-content .sub-title {
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
}
.newsletter-inner-area .newsletter-content h2 {
  color: #ffffff;
  font-size: 40px;
}
.newsletter-inner-area .newsletter-content .newsletter-form {
  position: relative;
  margin-top: 30px;
}
.newsletter-inner-area .newsletter-content .newsletter-form .form-control {
  background-color: #ffffff;
  height: 60px;
  border-radius: 30px;
  padding: 10px 25px;
}
.newsletter-inner-area .newsletter-content .newsletter-form button {
  position: absolute;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  color: #ffffff;
  border: none;
  top: 0;
  right: 0;
  top: 5px;
  right: 5px;
  height: 50px;
  border-radius: 30px;
  padding: 0 30px;
  font-weight: 600;
}

/*================================================
Blog CSS
=================================================*/
.single-blog-post {
  margin-bottom: 30px;
  background-color: #f5f6f5;
}
.single-blog-post .post-image {
  position: relative;
  overflow: hidden;
}
.single-blog-post .post-image a {
  display: block;
}
.single-blog-post .post-image img {
  transition: all 0.5s ease;
  width: 100%;
}
.single-blog-post .post-image::before, .single-blog-post .post-image::after {
  content: "";
  background: #ffffff;
  height: 0;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
}
.single-blog-post .post-image::after {
  bottom: 0;
  right: auto;
  top: auto;
  opacity: 0.7;
  left: 0;
}
.single-blog-post .post-content {
  padding: 30px 25px;
}
.single-blog-post .post-content .post-meta {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-blog-post .post-content .post-meta li {
  display: inline-block;
  margin-right: 18px;
  position: relative;
  color: #ff2c9c;
  padding-left: 20px;
  font-size: 14px;
}
.single-blog-post .post-content .post-meta li a {
  display: inline-block;
  color: #ff2c9c;
}
.single-blog-post .post-content .post-meta li a:hover, .single-blog-post .post-content .post-meta li a:focus {
  color: #ff2c9c;
}
.single-blog-post .post-content .post-meta li i {
  margin-right: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-blog-post .post-content .post-meta li::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  width: 1px;
  height: 15px;
  background: #646464;
  transform: translateY(-50%);
}
.single-blog-post .post-content .post-meta li:first-child {
  padding-left: 25px;
}
.single-blog-post .post-content .post-meta li:last-child {
  margin-right: 0;
}
.single-blog-post .post-content .post-meta li:last-child::before {
  display: none;
}
.single-blog-post .post-content h3 {
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
}
.single-blog-post .post-content h3 a {
  color: #18181b;
}
.single-blog-post .post-content h3 a:hover, .single-blog-post .post-content h3 a:focus {
  color: #ff2c9c;
}
.single-blog-post:hover .post-image img, .single-blog-post:focus .post-image img {
  transform: scale(1.3);
}
.single-blog-post:hover .post-image::after, .single-blog-post:hover .post-image::before, .single-blog-post:focus .post-image::after, .single-blog-post:focus .post-image::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}

.blog-item-box .blog-item {
  margin-bottom: 21px;
  background-color: #f5f6f5;
}
.blog-item-box .blog-item .row {
  margin-left: -10px;
  margin-right: -10px;
}
.blog-item-box .blog-item .row .col-lg-4, .blog-item-box .blog-item .row .col-lg-8 {
  padding-left: 10px;
  padding-right: 10px;
}
.blog-item-box .blog-item .post-content .post-meta {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-item-box .blog-item .post-content .post-meta li {
  display: inline-block;
  margin-right: 18px;
  position: relative;
  color: #ff2c9c;
  padding-left: 20px;
  font-size: 14px;
}
.blog-item-box .blog-item .post-content .post-meta li a {
  display: inline-block;
  color: #ff2c9c;
}
.blog-item-box .blog-item .post-content .post-meta li a:hover, .blog-item-box .blog-item .post-content .post-meta li a:focus {
  color: #ff2c9c;
}
.blog-item-box .blog-item .post-content .post-meta li i {
  margin-right: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.blog-item-box .blog-item .post-content .post-meta li::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 50%;
  width: 1px;
  height: 12px;
  background: #646464;
  transform: translateY(-50%);
}
.blog-item-box .blog-item .post-content .post-meta li:last-child {
  margin-right: 0;
}
.blog-item-box .blog-item .post-content .post-meta li:last-child::before {
  display: none;
}
.blog-item-box .blog-item .post-content h3 {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 32px;
  font-size: 20px;
  font-weight: 600;
}
.blog-item-box .blog-item .post-content h3 a {
  color: #18181b;
}
.blog-item-box .blog-item .post-content h3 a:hover, .blog-item-box .blog-item .post-content h3 a:focus {
  color: #ff2c9c;
}
.blog-item-box .blog-item .post-image {
  overflow: hidden;
  position: relative;
}
.blog-item-box .blog-item .post-image img {
  transition: all 0.5s ease;
}
.blog-item-box .blog-item .post-image::before, .blog-item-box .blog-item .post-image::after {
  content: "";
  background: #ffffff;
  height: 0;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
}
.blog-item-box .blog-item .post-image::after {
  bottom: 0;
  right: auto;
  top: auto;
  opacity: 0.7;
  left: 0;
}
.blog-item-box .blog-item:hover .post-image img, .blog-item-box .blog-item:focus .post-image img {
  transform: scale(1.3);
}
.blog-item-box .blog-item:hover .post-image::after, .blog-item-box .blog-item:hover .post-image::before, .blog-item-box .blog-item:focus .post-image::after, .blog-item-box .blog-item:focus .post-image::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}
.blog-item-box .blog-item:last-child {
  margin-bottom: 0;
}

/* Blog style two */
.single-blog-item {
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
}
.single-blog-item .blog-image {
  position: relative;
  overflow: hidden;
}
.single-blog-item .blog-image a {
  display: block;
}
.single-blog-item .blog-image a img {
  transition: all 0.5s ease;
  width: 100%;
}
.single-blog-item .blog-image .post-tag {
  position: absolute;
  left: 0;
  top: 40px;
  transition: all 0.5s ease;
}
.single-blog-item .blog-image .post-tag a {
  background: #ff2c9c;
  color: #ffffff;
  padding: 9px 28px;
}
.single-blog-item .blog-post-content {
  padding: 30px;
}
.single-blog-item .blog-post-content .date {
  color: #ff2c9c;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 0.5s ease;
  margin-bottom: 10px;
}
.single-blog-item .blog-post-content h3 {
  margin-bottom: 10px;
  line-height: 1.4;
  font-size: 24px;
}
.single-blog-item .blog-post-content h3 a {
  color: #18181b;
}
.single-blog-item .blog-post-content h3 a:hover, .single-blog-item .blog-post-content h3 a:focus {
  color: #ff2c9c;
}
.single-blog-item .blog-post-content p {
  margin-bottom: 15px;
}
.single-blog-item:hover .blog-image a img, .single-blog-item:focus .blog-image a img {
  transform: scale(1.2);
}
.single-blog-item:hover .blog-image .post-tag, .single-blog-item:focus .blog-image .post-tag {
  top: 50px;
}
.single-blog-item:hover .blog-image .post-tag a, .single-blog-item:focus .blog-image .post-tag a {
  background: #ff2c9c;
  color: #ffffff;
}

.blog-slides .swiper-pagination {
  position: initial;
}
.blog-slides .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #D6D6D6;
  border: 1px solid #b7b7b7;
  opacity: 1;
}
.blog-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details .article-img img {
  width: 100%;
}
.blog-details .article-content {
  padding: 30px 30px 35px;
  border: 1px solid #eeeeee;
}
.blog-details .article-content .entry-meta {
  padding: 0;
  margin: 0 0 20px;
  list-style-type: none;
}
.blog-details .article-content .entry-meta li {
  display: inline-block;
  color: #646464;
  margin-right: 15px;
  font-size: 14px;
}
.blog-details .article-content .entry-meta li i {
  color: #ff2c9c;
  margin-right: 6px;
}
.blog-details .article-content .entry-meta li:last-child {
  margin-right: 0;
}
.blog-details .article-content .entry-meta li a {
  display: inline-block;
  color: #646464;
}
.blog-details .article-content .entry-meta li a:hover {
  color: #ff2c9c;
}
.blog-details .article-content .category {
  padding: 0;
  margin: 30px 0 0;
  list-style-type: none;
}
.blog-details .article-content .category li {
  display: inline-block;
  margin-right: 10px;
}
.blog-details .article-content .category li span {
  font-weight: 600;
}
.blog-details .article-content .category li a {
  border: 1px solid #ff2c9c;
  border-radius: 5px;
  padding: 4px 15px;
  font-size: 13px;
  color: #ff2c9c;
  position: relative;
  z-index: 1;
  margin: 5px 0;
}
.blog-details .article-content .category li a::before {
  position: absolute;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.blog-details .article-content .category li a:hover, .blog-details .article-content .category li a:focus {
  color: #ffffff;
}
.blog-details .article-content .category li a:hover::before, .blog-details .article-content .category li a:focus::before {
  opacity: 1;
  visibility: visible;
}

blockquote {
  background-color: #fafafa;
  padding: 30px 30px 30px 70px !important;
  line-height: 25px;
  font-weight: 500;
  position: relative;
  margin: 20px 0;
}
blockquote::before {
  font-family: "Font Awesome 5 Free";
  content: "\f10d";
  position: absolute;
  left: 30px;
  top: 35px;
  color: #ff2c9c;
  font-size: 30px;
  font-weight: 900;
}
blockquote p {
  font-weight: 600;
}
blockquote cite {
  position: relative;
  padding-left: 17px;
  font-family: "Saira", sans-serif;
  font-weight: 600;
}
blockquote cite::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  left: 0;
  width: 10px;
  height: 2px;
}

.post-controls-buttons {
  overflow: hidden;
  padding: 30px;
  border: 1px solid #eeeeee;
  margin-top: 30px;
  margin-bottom: 30px;
}
.post-controls-buttons div:first-child {
  float: left;
}
.post-controls-buttons div:last-child {
  float: right;
}
.post-controls-buttons div a {
  display: inline-block;
  border: 1px solid #eeeeee;
  padding: 9px 20px;
  border-radius: 5px;
  color: #18181b;
  position: relative;
  z-index: 1;
  font-weight: 600;
}
.post-controls-buttons div a::before {
  position: absolute;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  z-index: -1;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.post-controls-buttons div a:hover, .post-controls-buttons div a:focus {
  border-color: #ff2c9c;
  color: #ffffff;
}
.post-controls-buttons div a:hover::before, .post-controls-buttons div a:focus::before {
  opacity: 1;
  visibility: visible;
}

.comments-area .comments-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  margin-top: 30px;
}
.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .children {
  margin-left: 65px;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  margin-left: 65px;
  color: #18181b;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #ff2c9c;
  color: #ff2c9c;
  display: inline-block;
  padding: 5px 20px 6px;
  border-radius: 30px;
  font-size: 13px;
  position: relative;
  z-index: 1;
}
.comments-area .comment-body .reply a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  border-radius: 30px;
  z-index: -1;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
}
.comments-area .comment-body .reply a:hover::before {
  opacity: 1;
  visibility: visible;
}
.comments-area .comment-meta {
  margin-bottom: 1em;
}
.comments-area .comment-author {
  font-size: 15px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-weight: 600;
  font-family: "Saira", sans-serif;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  color: #646464;
  letter-spacing: 0.1818em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
.comments-area .comment-metadata a {
  color: #646464;
}
.comments-area .comment-metadata a:hover {
  color: #ff2c9c;
}
.comments-area .comment-content p {
  font-size: 14px;
}
.comments-area .comment-respond {
  background: #fafafa;
  padding: 30px 25px;
  overflow: hidden;
  border: 1px solid #eeeeee;
}
.comments-area .comment-respond .comment-reply-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  font-size: 15px;
  display: inline-block;
}
.comments-area .comment-respond .comment-notes {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #18181b;
}
.comments-area .comment-respond input[type=date], .comments-area .comment-respond input[type=time], .comments-area .comment-respond input[type=datetime-local], .comments-area .comment-respond input[type=week], .comments-area .comment-respond input[type=month], .comments-area .comment-respond input[type=text], .comments-area .comment-respond input[type=email], .comments-area .comment-respond input[type=url], .comments-area .comment-respond input[type=password], .comments-area .comment-respond input[type=search], .comments-area .comment-respond input[type=tel], .comments-area .comment-respond input[type=number], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: all 0.5s ease;
}
.comments-area .comment-respond input[type=date]:focus, .comments-area .comment-respond input[type=time]:focus, .comments-area .comment-respond input[type=datetime-local]:focus, .comments-area .comment-respond input[type=week]:focus, .comments-area .comment-respond input[type=month]:focus, .comments-area .comment-respond input[type=text]:focus, .comments-area .comment-respond input[type=email]:focus, .comments-area .comment-respond input[type=url]:focus, .comments-area .comment-respond input[type=password]:focus, .comments-area .comment-respond input[type=search]:focus, .comments-area .comment-respond input[type=tel]:focus, .comments-area .comment-respond input[type=number]:focus, .comments-area .comment-respond textarea:focus {
  border-color: #ff2c9c;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: relative;
  top: 1px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #646464;
  font-weight: normal;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
  color: #ffffff;
  padding: 11px 25px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  opacity: 0.5;
}

.text-container h1, .text-container h2, .text-container h3, .text-container h4, .text-container h5, .text-container h6 {
  margin-bottom: 15px;
}
.text-container ul, .text-container ol {
  padding-left: 17px;
}
.text-container ul li, .text-container ol li {
  line-height: 1.8;
  margin-bottom: 8px;
}
.text-container ul li:last-child, .text-container ol li:last-child {
  margin-bottom: 0;
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
  position: relative;
  background-size: cover;
  background-position: center center;
  padding-top: 230px;
  padding-bottom: 160px;
  text-align: center;
}
.page-title-area h2 {
  margin: 0;
  color: #ffffff;
  font-size: 50px;
}
.page-title-area::before {
  content: "";
  background-color: #18181b;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.page-title-area .container {
  position: relative;
}

.shape1 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 30px;
  animation: rotate3d 4s linear infinite;
  opacity: 0.4;
}

.shape2 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
}

.shape3 {
  position: absolute;
  left: 25%;
  bottom: 15%;
  z-index: -1;
  animation: animationFramesOne 15s infinite linear;
}

.shape4 {
  position: absolute;
  right: 25%;
  bottom: 15%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.shape5 {
  position: absolute;
  right: 5%;
  top: 10%;
  z-index: -1;
  animation: movebounce 5s linear infinite;
  opacity: 0.2;
}

.shape6 {
  position: absolute;
  z-index: -1;
  top: 40%;
  right: 10%;
}

.shape7 {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.shape8 {
  position: absolute;
  z-index: -1;
  top: 15%;
  right: 10%;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
/*================================================
Contact CSS
=================================================*/
.contact-info-box {
  transition: all 0.5s ease;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 30px 30px 30px 120px;
  margin-bottom: 30px;
  position: relative;
}
.contact-info-box .icon {
  display: inline-block;
  width: 65px;
  height: 65px;
  line-height: 65px;
  background: #f7fafd;
  border-radius: 50%;
  font-size: 30px;
  color: #ff2c9c;
  transition: all 0.5s ease;
  position: absolute;
  left: 30px;
  text-align: center;
}
.contact-info-box h3 {
  font-size: 20px;
  margin: 0 0 10px;
}
.contact-info-box p {
  margin-bottom: 0;
  color: #646464;
}
.contact-info-box p a {
  color: #646464;
}
.contact-info-box p a:hover {
  color: #ff2c9c;
}
.contact-info-box:hover {
  transform: translateY(-10px);
}
.contact-info-box:hover .icon {
  color: #ffffff;
  background: #ff2c9c;
}

.contact-area .section-title {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}
.contact-area .section-title p {
  margin-left: 0;
  margin-right: 0;
}
.contact-area .section-title h2 {
  margin-bottom: 12px;
}

.contact-form {
  background-color: #ffffff;
  text-align: center;
}
.contact-form .contact-form-box {
  padding: 30px;
  border: 1px solid #eee;
}
.contact-form .contact-title {
  margin-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.contact-form .contact-title h2 {
  font-size: 36px;
  margin-bottom: 15px;
}

textarea.form-control {
  height: auto !important;
}

.contact-sidebar .contact-item {
  position: relative;
  padding-left: 52px;
  margin-bottom: 30px;
}
.contact-sidebar .contact-item:last-child {
  margin-bottom: 0;
}
.contact-sidebar .contact-item .icon {
  font-size: 35px;
  color: #ff2c9c;
  position: absolute;
  left: 0;
  top: 0;
}
.contact-sidebar .contact-item span {
  font-size: 18px;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.contact-sidebar .contact-item p {
  margin-bottom: 0;
}
.contact-sidebar .contact-item p a {
  color: #646464;
}
.contact-sidebar .contact-item p a:hover {
  color: #ff2c9c;
}

/*================================================
404 Error CSS
=================================================*/
.error-content {
  margin: 0 auto;
  max-width: 650px;
  text-align: center;
}
.error-content .search-form {
  position: relative;
  margin-top: 45px;
}
.error-content .search-form .search-field {
  display: block;
  width: 100%;
  height: 50px;
  outline: 0;
  box-shadow: unset;
  padding-left: 15px;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid #eeeeee;
}
.error-content .search-form .search-submit {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 110px;
  outline: 0;
  box-shadow: unset;
  border: none;
  background: #ff2c9c;
  color: #ffffff;
  font-weight: 600;
  transition: all 0.5s ease;
}
.error-content .search-form .search-submit:hover, .error-content .search-form .search-submit:focus {
  background-color: #18181b;
  color: #ffffff;
}
.error-content h3 {
  margin-top: 30px;
}
.error-content .back-btn {
  text-align: center;
  margin-top: 20px;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 30px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 40px;
  color: #18181b;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 17px;
  font-weight: 600;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: #ff2c9c;
  color: #ffffff;
  box-shadow: unset;
}

/*================================================
Sidebar CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  font-weight: 700;
  font-size: 21px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.widget-area .widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.widget-area .widget ul li {
  position: relative;
  margin-bottom: 12px;
  color: #646464;
  padding-left: 17px;
  font-size: 14px;
  line-height: 1.8;
}
.widget-area .widget ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget ul li::before {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}
.widget-area .widget ul li a {
  color: #646464;
}
.widget-area .widget ul li a:hover, .widget-area .widget ul li a:focus {
  color: #ff2c9c;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  background-color: #f8f8f8;
  height: 50px;
  padding: 6px 18px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: all 0.5s ease;
}
.widget-area .widget_search form .search-submit {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background: #ff2c9c;
  outline: 0;
  border: none;
  color: #ffffff;
  height: 50px;
  padding: 0 20px;
  transition: all 0.5s ease;
  font-weight: 600;
  font-size: 15px;
}
.widget-area .widget_search form .search-submit:hover {
  background: #18181b;
  color: #ffffff;
}
.widget-area .widget_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../images//blog/blog1.jpg);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../images/blog/blog2.jpg);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../images/blog/blog3.jpg);
}
.widget-area .widget_posts_thumb .item .info {
  overflow: hidden;
}
.widget-area .widget_posts_thumb .item .info time {
  display: block;
  color: #646464;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 12px;
}
.widget-area .widget_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
}
.widget-area .widget_posts_thumb .item .info .title a {
  color: #18181b;
}
.widget-area .widget_posts_thumb .item .info .title a:hover {
  color: #ff2c9c;
}
.widget-area .widget_recent_entries ul li {
  font-size: 16px;
}
.widget-area .widget_recent_entries ul li a {
  font-size: 16px;
  font-weight: 600;
  color: #18181b;
}
.widget-area .widget_recent_entries ul li a:hover {
  color: #ff2c9c;
}
.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #646464;
  margin-top: 4px;
}
.widget-area .widget_recent_comments ul li {
  color: #646464;
}
.widget-area .widget_recent_comments ul li span {
  display: inline-block;
}
.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: #18181b;
  font-weight: 600;
}
.widget-area .widget_recent_comments ul li a:hover {
  color: #ff2c9c;
}
.widget-area .widget_archive ul li a {
  font-weight: 600;
}
.widget-area .widget_categories ul li a {
  display: block;
  font-weight: 600;
}
.widget-area .widget_categories ul li .post-count {
  float: right;
  font-size: 14px;
}
.widget-area .widget_meta ul li a {
  font-weight: 600;
}
.widget-area .tagcloud a {
  display: inline-block;
  font-size: 14px;
  position: relative;
  z-index: 1;
  padding: 7px 10px;
  color: #646464;
  border: 1px solid #eeeeee;
  margin-top: 8px;
  margin-right: 10px;
  font-weight: 600;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #ff2c9c;
}

/*================================================
Case Studies Details CSS
=================================================*/
.case-studies-details-image {
  overflow: hidden;
  margin-bottom: 30px;
}
.case-studies-details-image img {
  transition: all 0.5s ease;
}
.case-studies-details-image a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.case-studies-details-image:hover img, .case-studies-details-image:focus img {
  transform: scale(1.3);
}

.case-studies-desc {
  margin-top: 20px;
}
.case-studies-desc h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.case-studies-information {
  margin-top: 20px;
}
.case-studies-information h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.case-studies-information ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.case-studies-information ul li {
  margin-bottom: 12px;
  color: #646464;
}
.case-studies-information ul li span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
  color: #18181b;
  display: inline-block;
  margin-right: 4px;
}
.case-studies-information ul li a {
  display: inline-block;
  color: #646464;
}
.case-studies-information ul li a:hover {
  color: #ff2c9c;
}

/*================================================
Portfolio Details CSS
=================================================*/
.portfolio-details-image {
  position: relative;
  overflow: hidden;
}
.portfolio-details-image img {
  transition: all 0.5s ease;
}
.portfolio-details-image .popup-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: all-scroll;
}
.portfolio-details-image:hover img, .portfolio-details-image:focus img {
  transform: scale(1.3);
}

.portfolio-desc {
  margin-top: 50px;
}
.portfolio-desc h1, .portfolio-desc h2, .portfolio-desc h3, .portfolio-desc h4, .portfolio-desc h5, .portfolio-desc h6 {
  margin-bottom: 15px;
}
.portfolio-desc h1 {
  font-size: 35px;
}
.portfolio-desc h2 {
  font-size: 32px;
}
.portfolio-desc h3 {
  font-size: 29px;
}
.portfolio-desc h4 {
  font-size: 26px;
}
.portfolio-desc h5 {
  font-size: 23px;
}
.portfolio-desc h6 {
  font-size: 20px;
}
.portfolio-desc ul li, .portfolio-desc ol li {
  line-height: 1.4;
  margin-bottom: 5px;
}
.portfolio-desc ul li:last-child, .portfolio-desc ol li:last-child {
  margin-bottom: 0;
}

.portfolio-information {
  margin-top: 50px;
}
.portfolio-information h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.portfolio-information ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.portfolio-information ul li {
  margin-bottom: 12px;
  color: #646464;
}
.portfolio-information ul li span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
  color: #18181b;
  display: inline-block;
  margin-right: 4px;
}
.portfolio-information ul li a {
  display: inline-block;
  color: #646464;
}
.portfolio-information ul li a:hover {
  color: #ff2c9c;
}

.service-details-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  background: #f4f5fe;
  padding: 30px 20px 10px;
  border-radius: 10px;
}
.service-details-info .single-info-box {
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.service-details-info .single-info-box h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
.service-details-info .single-info-box span {
  display: block;
  color: #646464;
  font-size: 15px;
}
.service-details-info .single-info-box .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.service-details-info .single-info-box .social li {
  display: inline-block;
  margin-right: 5px;
}
.service-details-info .single-info-box .social li a {
  background-color: #ffffff;
  color: #646464;
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 26px;
  line-height: 26px;
  font-size: 13px;
}
.service-details-info .single-info-box .social li a:hover {
  color: #ff2c9c;
  transform: translateY(-2px);
}
.service-details-info .single-info-box .default-btn {
  display: inline-block;
  padding: 12px 30px;
  color: #ffffff;
  text-transform: capitalize;
  background-color: #ff2c9c;
  border: 1px solid #ff2c9c;
  transition: 0.5s;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
}
.service-details-info .single-info-box .default-btn:hover {
  background-color: #ffffff;
  color: #ff2c9c;
}
.service-details-info .single-info-box:last-child {
  text-align: center;
}

.pd-preview-img {
  position: relative;
}
.pd-preview-img img {
  border-radius: 10px;
}
.pd-preview-img .video-box .video-btn {
  background-color: #f9004d;
  color: #fff;
  width: 90px;
  height: 90px;
}
.pd-preview-img .video-box .video-btn:before, .pd-preview-img .video-box .video-btn:after {
  border: 1px solid #f9004d;
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none;
}
.faq-accordion .accordion .accordion__item {
  margin-bottom: 15px;
  border: none;
}
.faq-accordion .accordion .accordion__item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion__item .accordion__button {
  display: block;
  padding: 12px 45px 12px 15px;
  text-decoration: none;
  position: relative;
  background: #f5f9ff;
  color: #18181b;
  font-size: 18px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
}
.faq-accordion .accordion .accordion__item .accordion__button:hover, .faq-accordion .accordion .accordion__item .accordion__button.active, .faq-accordion .accordion .accordion__item .accordion__button:focus {
  background: #ff2c9c;
  color: #ffffff;
  outline: 0;
}
.faq-accordion .accordion .accordion__item .accordion__button::before {
  position: relative;
  top: -2px;
}
.faq-accordion .accordion .accordion__item .accordion-content {
  display: none;
  position: relative;
  overflow: hidden;
  padding: 15px 0 0;
  line-height: 1.9;
}
.faq-accordion .accordion .accordion__item .accordion-content.show {
  display: block;
}
.faq-accordion .accordion .accordion__panel {
  padding: 20px;
  border: 1px solid #eee;
}

.faq-contact {
  margin-top: 45px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  border-radius: 4px;
  background: #ffffff;
  text-align: center;
}
.faq-contact h3 {
  margin-bottom: 35px;
  font-size: 24px;
  font-family: "Saira", sans-serif;
}
.faq-contact form .btn {
  margin-top: 10px;
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  background-color: #f6f9fc;
  padding-top: 100px;
  position: relative;
  z-index: 1;
}
.logo{
  width: 72%;
}

.single-footer-widget .logo {
  margin-bottom: 20px;
}
.single-footer-widget .logo a {
  font-size: 24px;
  font-weight: bold;
  color: #18181b;
}
.single-footer-widget .social-links {
  padding: 0;
  margin: 20px 0 0;
  list-style-type: none;
}
.single-footer-widget .social-links li {
  display: inline-block;
  margin-right: 2px;
}
.single-footer-widget .social-links li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #4a4a4a;
  border-radius: 50%;
  color: #ffffff;
  position: relative;
  z-index: 1;
  font-size: 13px;
  margin-right: 5px;
}
.single-footer-widget .social-links li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  width: 0;
  height: 0;
  z-index: -1;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.single-footer-widget .social-links li a:hover::before, .single-footer-widget .social-links li a:focus::before {
  width: 100%;
  height: 100%;
}
.single-footer-widget .social-links li:last-child {
  margin: 0;
}
.single-footer-widget h3 {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 24px;
  font-weight: 600;
}
.single-footer-widget h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 55px;
  height: 3px;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
}
.single-footer-widget h3::after {
  content: "";
  position: absolute;
  left: 35px;
  bottom: -2px;
  height: 3px;
  background: #f6f9fc;
  width: 5px;
}
.single-footer-widget ul.list {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.single-footer-widget ul.list li {
  margin-bottom: 12px;
}
.single-footer-widget ul.list li a {
  position: relative;
  color: #646464;
}
.single-footer-widget ul.list li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  width: 10px;
  height: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.single-footer-widget ul.list li a:hover, .single-footer-widget ul.list li a:focus {
  color: #ff2c9c;
  padding-left: 15px;
}
.single-footer-widget ul.list li a:hover::before, .single-footer-widget ul.list li a:focus::before {
  opacity: 1;
  visibility: visible;
}
.single-footer-widget ul.list li:last-child {
  margin-bottom: 0;
}
.single-footer-widget ul.get-in-touch {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-footer-widget ul.get-in-touch li {
  position: relative;
  margin-bottom: 20px !important;
  padding-left: 50px;
}
.single-footer-widget ul.get-in-touch li:first-child {
  margin-bottom: 15px;
}
.single-footer-widget ul.get-in-touch li i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #ffffff;
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.single-footer-widget ul.get-in-touch li a {
  display: inline-block;
  color: #646464;
}
.single-footer-widget ul.get-in-touch li a:hover, .single-footer-widget ul.get-in-touch li a:focus {
  color: #ff2c9c;
}
.single-footer-widget ul.get-in-touch li:last-child {
  margin-bottom: 0 !important;
}

.copyright-area {
  background-color: #000000;
  text-align: center;
  margin-top: 95px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copyright-area p {
  color: #ffffff;
  font-size: 15px;
}
.copyright-area p a {
  display: inline-block;
  color: #ff2c9c;
}
.copyright-area p a:hover, .copyright-area p a:focus {
  text-decoration: underline;
}

.footer-dark {
  background-color: #000 !important;
}
.footer-dark p {
  color: #fff;
}
.footer-dark .single-footer-widget h3 {
  color: #fff;
  border-bottom: 1px solid #2e2e2e;
}
.footer-dark .single-footer-widget ul.list li a {
  color: #c9c9c9;
}
.footer-dark .single-footer-widget ul.get-in-touch li {
  color: #c9c9c9;
}
.footer-dark .single-footer-widget ul.get-in-touch li a {
  color: #c9c9c9;
}
.footer-dark .copyright-area {
  background-color: #040404;
  border-top: 1px solid #12141a;
}

/*================================================
Go Top CSS
=================================================*/
.scroll-to-top i {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  font-size: 35px;
  color: #ffffff;
  background-color: #18181b;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.scroll-to-top i:hover {
  color: #ffffff;
  background: #ff2c9c;
}

.lang-didebar {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 999999;
}
.lang-didebar a {
  background-color: #000;
  color: #fff;
  display: block;
  padding: 5px 9px;
  font-size: 14px;
}
.lang-didebar a:last-child {
  background-color: #483e3e;
}

span.new {
  position: absolute;
  background: #f9004d;
  color: #fff;
  font-weight: 400;
  padding: 1px 5px;
  font-size: 8px;
  border-radius: 30px;
  top: 7px;
}

/* Coming Soon Area CSS */
.coming-soon-area {
  height: 100vh;
  background-image: url(../images/coming-soon.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.coming-soon-content {
  background-color: #fff;
  border-radius: 10px;
  max-width: 650px;
  padding: 40px;
}
.coming-soon-content .logo {
  display: inline-block;
}
.coming-soon-content h2 {
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 0;
}
.coming-soon-content .coming-soon-countdown {
  list-style-type: none;
  padding-left: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.coming-soon-content .coming-soon-countdown li {
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 20px;
}
.coming-soon-content .coming-soon-countdown li span {
  color: #ff2c9c;
  font-size: 40px;
  font-weight: 700;
}
.coming-soon-content .coming-soon-countdown li:first-child {
  margin-left: 0;
}
.coming-soon-content .coming-soon-countdown li:last-child {
  margin-right: 0;
}
.coming-soon-content form {
  position: relative;
  margin-top: 40px;
}
.coming-soon-content form .input-newsletter {
  width: 100%;
  height: 60px;
  border: none;
  display: block;
  border-radius: 4px;
  padding: 0 0 0 20px;
  color: #000;
  font-size: 15px;
  transition: all 0.5s ease;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.coming-soon-content form .input-newsletter::-moz-placeholder {
  color: #646464;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.coming-soon-content form .input-newsletter::placeholder {
  color: #646464;
  transition: all 0.5s ease;
}
.coming-soon-content form .input-newsletter:focus {
  border-color: #ff2c9c;
}
.coming-soon-content form .input-newsletter:focus::-moz-placeholder {
  color: transparent;
}
.coming-soon-content form .input-newsletter:focus::placeholder {
  color: transparent;
}
.coming-soon-content form .default-btn-two {
  top: 5px;
  right: 5px;
  bottom: 5px;
  position: absolute;
}

.quotes-slides .swiper-pagination {
  position: relative;
  margin-top: 20px;
}
.quotes-slides .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #D6D6D6;
  border: 1px solid #b7b7b7;
  opacity: 1;
}
.quotes-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  border: 1px solid #ff2c9c;
}

.curve-shape, .oval-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.curve-shape img, .oval-shape img {
  width: 100%;
}

.partner-slides .swiper-button-prev {
  left: -6px;
}
.partner-slides .swiper-button-prev:after {
  color: #ff2c9c;
  font-size: 26px;
}
.partner-slides .swiper-button-next {
  right: -6px;
}
.partner-slides .swiper-button-next:after {
  color: #ff2c9c;
  font-size: 26px;
}/*# sourceMappingURL=style.css.map */

/* Custom */

.navbar-brand img{
  max-width: 40%;
}