.floating-button-div {
  position: fixed;
  bottom: 0px;
  right: 5px;
}

.lang {
  background-color: #282c34;
  color: white;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  display: flex;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.lang .flag {
  width: 25px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .floating-button-div {
    display: none;
  }
}
